import React, { Component } from 'react'
import { graphql } from 'gatsby'
//import he from 'he'
import moment from 'moment'

import SearchForm from '../components/search'

import Seo from '../components/seo'
import PropertyCard from '../components/cards/property'
import Thinking from '../components/thinking'

class BuyPage extends Component {

  state = {
    display: this.props.data.properties || []
  }

  render() {

    let { display } = this.state
    const { properties } = this.props.data

    return (
      <>
        <Seo title="Buy - Mara+Co" bodyClass='buying' description="We passionately believe that real estate is not about a transaction but about building relationships. Our sales experts come from varied backgrounds that ultimately define our shared core values: helping our clients above and beyond." />
        <section className="properties properties--buy">
          <SearchForm list={properties || []} set={list => this.setState({ display: list })} type='buy' />
          <div className="properties__inner">
            { display && display.edges.map((el, i) => {
              if ( i === 2 ) {
                return (
                  <>
                    <Thinking title={'buying'} short={'We passionately believe that real estate is not about a transaction but about building relationships.'} long={'Our sales experts come from varied backgrounds that ultimately define our shared core values: helping our clients above and beyond.'} key={i} />
                    <PropertyCard postType='property' {...el.node} key={i} />
                  </>
                )
              } else {
                return (
                  <PropertyCard postType='property' {...el.node} key={i} />
                )
              }
            })}
          </div>
        </section>
      </>
    )
  }
}

export default BuyPage

export const buyQuery = graphql`
  query {
    properties: allWpProperty(
      sort: {
        fields: propertyListing___onMarketDate,
        order: DESC
      },
      filter:{
        propertyListing :{ status:{in:["Available", "Conditional"]}}
      },
      limit: 100
    )
    {
      edges {
        node {
          databaseId
          id
          slug
          title
          propertyAddress {
            hideAddress
            streetAddress
            suburb
            state
          }
          propertyData {
            bathrooms
            bedrooms
            type
            carPorts
            carSpaces
            garages
            totalParking
          }
          propertyImages {
            url
            small
            large
          }
          propertyListing {
            searchPrice
            marketingStatus
            displayPrice
          }
        }
      }
    }
  }
`
